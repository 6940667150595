$(document).ready(function() {
    var pagina = $("body").attr("id");
    var idioma = $("html").attr("lang");
    
    if (idioma == "ES") {
        if (pagina == "nosotros") {
            $(".caption-seccion h1").text("Conoce a MNK Villas y sus villas en Menorca");
        } else if (pagina == "ofertas") {
            $(".caption-seccion h1").text("Disfruta de nuestras ofertas de villas en Menorca");
        }
    } if (idioma == "EN") {
        if (pagina == "nosotros") {
            $(".caption-seccion h1").text("About MNK Villas and their villas in Menorca");
        } else if (pagina == "ofertas") {
            $(".caption-seccion h1").text("Take advantage of our offers on villas in Menorca");
        }
    }
    
});